import { Controller } from "stimulus";

export default class extends Controller { 
  connect() {
    // Prevent scrolling on body when modal opens
    document.body.style.overflow = 'hidden';
    
    // Listen for custom close event
    document.addEventListener('modal:close', this.closeModal.bind(this));
  }

  disconnect() {
    // Re-enable scrolling when modal closes
    document.body.style.overflow = 'auto';
    
    // Clean up event listener
    document.removeEventListener('modal:close', this.closeModal.bind(this));
  }

  closeModal() {
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
  }

  close(event) {
    if (event.target === event.currentTarget) {
      this.closeModal();
    }
  }

  escClose(event) {
    if (event.key === "Escape") this.closeModal();
  }
}
