import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  connect() {
    this.channel = consumer.subscriptions.create(
      {
        channel: "AcknowledgementsChannel",
        organization_id: this.organizationIdValue
      },
      {
        received: this.handleReceived.bind(this)
      }
    )
  }

  disconnect() {
    this.channel.unsubscribe()
  }

  handleReceived(data) {
    const existingRow = this.element.querySelector(`#${data.member_id}`)
    if (existingRow) {
      existingRow.outerHTML = data.html
    }
  }

  get organizationIdValue() {
    return this.element.dataset.acknowledgementsOrganizationId
  }
} 